import React from "react"
import LayoutComponent from "../components/LayoutComponent/LayoutComponent"
import MenuComponent from "../components/MenuComponent/MenuComponent"
import "../styles/global.scss"
import ContactComponent from "../components/ContactComponent/ContactComponent"
import SEO from "../components/seo"

const Contact = () => {
  return (
    <LayoutComponent languageSwitchSubpage="contact">
      <SEO title="Kontakt" />
      <MenuComponent selected={5} />
      <ContactComponent />
    </LayoutComponent>
  )
}

export default Contact
